<template>
    <div id="homepageWrapper">
        <div class="mainWrapper">
            <header>
                <div class="headerButtonWrapper">
                    <inline-svg :src="isEnglish ?
                        require('../assets/imgs/main/button_language_uk.svg') :
                        require('../assets/imgs/main/button_language_no.svg')
                        "
                        @click="changeLanguage"
                        />
                </div>
            </header>
            <div class="mainText">
                <div class="underheaderText">
                    <inline-svg :src="isEnglish ?
                        require('../assets/imgs/main/intro text_uk.svg') :
                        require('../assets/imgs/main/intro text_no.svg')
                        "
                        />
            </div>
        </div>
        </div>
        <div class="fullHeightOptions">
            <menu-options />
        </div>
    </div>
</template>
<script>
import MenuOptions from './subcomponents/menuOptions.vue'

export default {
  name: 'Homepage',
  components: {MenuOptions},
  created() {
    this.$store.commit('changeTransitionMode', 'slide-up');
  },
  activated() {
    this.$store.commit('changeTransitionMode', 'slide-up');
  },
  methods: {
      changeLanguage() {
          this.$store.commit('changeLanguage');
      },
  }
}
</script>

<style lang="scss">

body {
    #homepageWrapper {
        background-color: var(--background-color);
        height: 100%;
    }

    .underheaderText {
        padding: 60px 0 0 64px;

    }
    
    .headerButtonWrapper svg {
        padding: 54px 0 0 64px;
    }

    img {
        font-family: 'TradeGothicRegular', sans-serif;
    }
}


</style>