<template>
    <div id="timespanDetailsWrapper">
        <div class="intro">
            <inline-svg class="introText" :src="require(`../../assets/imgs/${year}/intro_${chosenLanguage}.svg`)"/>
            <img class="introImg" :src="require(`../../assets/imgs/${year}/intro.jpg`)"/>
            <inline-svg class="introSignature" :src="require(`../../assets/imgs/${year}/intro_sub_${chosenLanguage}.svg`)"/>
        </div>
       <div v-for="i in rows" :key="i" :class="`entry${i}`" :id="`anchor${i}`">
            <a href="#" :v-scroll-to="`#anchor${i}`"/>
            <inline-svg :class="`text${i}`" :src="require(`../../assets/imgs/${year}/0${i}_${chosenLanguage}.svg`)"/>
            <img 
                :src="require(`../../assets/imgs/${year}/0${i}.jpg`)"
                :class="`image${i}`"                
                @click="openPicture(i)"
                />
            <inline-svg :class="`signature${i}`" :src="require(`../../assets/imgs/${year}/0${i}_sub_${chosenLanguage}.svg`)"/>
       </div>
       <transition mode="out-in" name="fade">
        <div class="fullwidthImg" v-if="pictureShown">
            <inline-svg class="closingBtn" :src="require(`../../assets/imgs/gui/close.svg`)" @click="closePicture()"/>
            <img :src="require(`../../assets/imgs/${year}/0${clickedPicture}_full.jpg`)"/>
        </div>
       </transition>
       <div class="navArrows">
            <inline-svg
                :src="require('../../assets/imgs/gui/arrow_forward.svg')"
                @click="previousSection"
                class="previousArrow"
                :style="{visibility: ifFirstTimespan}"
                />
            <div class="spacer" :style="{width: '150px'}"/>
            <inline-svg
                :src="require('../../assets/imgs/gui/arrow_forward.svg')"
                @click="nextSection"
                class="nextArrow"
                :style="{visibility: ifLastTimespan}"
                />
       </div>
    </div>
</template>
<script>
export default {
    name: 'TimespanDetails',
    props: {
        year: String,
        rows: Number
    },
    data() {
        return {
            pictureShown: false,
            timespans: ['1893', '1925', '1949', '1951', '1956', '1960', '1982', '1993', '2016'],
            rowsNumbers: [8, 6, 4, 3, 3, 4, 3, 9, 5],
            clickedPicture: '',
        }
    },
    computed: {
        ifLastTimespan() {
            return this.year === '2016' ? 'hidden' : 'unset';
        },
        ifFirstTimespan() {
            return this.year === '1893' ? 'hidden' : 'unset';
        },
    },
    methods: {
        nextSection() {
            const currentIndex = this.timespans.indexOf(this.year);
            this.$router.push({name: 'timespan', params: {year: this.timespans[currentIndex+1], rows: this.rowsNumbers[currentIndex+1]}}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                        console.error(err);
                }
            });
        },
        previousSection() {
            const currentIndex = this.timespans.indexOf(this.year);
            this.$router.push({name: 'timespan', params: {year: this.timespans[currentIndex-1], rows: this.rowsNumbers[currentIndex-1]}}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                        console.error(err);
                }
            });
        },
        openPicture(index) {
            this.pictureShown = true;
            this.clickedPicture = index;
        },
        closePicture() {
            this.pictureShown = false;
            this.clickedPicture = '';
        },
    }
}
</script>

<style lang="scss">

#timespanDetailsWrapper {
    overflow: scroll;
    height: auto;
    max-height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

     &::-webkit-scrollbar {
        display: none;
        width: -1px;
    }

    div[class^='entry'] {
        padding-top: 15px;

        svg:first-of-type {
            padding-bottom: 15px;
        }
    }

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .intro {
        padding-top: 174px;
    }

    .signature {
        padding-bottom: 151px;
    }

    .navArrows {
        float: right;
        display: flex;

        .nextArrow {
            padding: 7px 64px 61px 0;
        }

        .previousArrow {
            padding: 0 0 8px 0;
            transform: rotate(180deg);
        }
    }

    .fullwidthImg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 3;

        img {
            height: 100%;
            width: 100%;
        }

        .closingBtn {
            position: absolute;
            top: 45px;
            right: 40px;
        }
    }
}
</style>