<template>
    <div id="timespanWrapper"  :class="{blueBackground: ifDiffBackground}">
        <div class="leftSide">
            <header>
                    <div class="headerButtonWrapper">
                        <inline-svg :src="require('../assets/imgs/gui/button_home.svg')"
                            @click="goHome"
                        />
                    </div>
            </header>
            <div class="mainText">
                <div class="underheaderText">
                    <inline-svg :src="isEnglish ?
                        require(`../assets/imgs/${year}/title_uk.svg`) :
                        require(`../assets/imgs/${year}/title_no.svg`)
                        "
                    />
                </div>
                <div class="arrows">
                    <inline-svg class="arrowUp" 
                        :src="require('../assets/imgs/gui/button_up.svg')" 
                        @click="scrollUp()"/>
                    <div class="spacer" :style="{height: '64px'}" />
                    <inline-svg class="arrowDown"
                        :src="require('../assets/imgs/gui/button_down.svg')"
                        @click="scrollDown()"/>
                </div>
            </div>
        </div>
        <div class="rightSide">
            <timespan-details :year="year" :rows="rows"/>
        </div>
    </div>
</template>
<script>
import TimespanDetails from './subcomponents/timespanDetails.vue';

export default {
    name: 'TimespanPage',
    components: {TimespanDetails},
    props: {
        year: String,
        rows: Number
    },
    data() {
        return {
            diffBackgroundCol: ['1925', '1951', '1960', '1993'],
            arrowsEnabled: true,
        }
    },
    computed: {
        ifDiffBackground() {
            return this.diffBackgroundCol.includes(this.year);
        }
    },
    watch: {
      isIdle(newVal) {
        if (newVal == true) {
            this.$store.commit('changeLanguage');
            this.redirectHome()
        }
      }
    },
    activated() {
        this.$store.commit('changeTransitionMode', 'slide-up');
    },
    methods: {
        goHome() {
            this.$store.commit('changeTransitionMode', 'slide-down');
            this.redirectHome()
        },
        scrollDown() {
            if (this.arrowsEnabled) {
                this.arrowsEnabled = false;
                let i = this.entryInViewport();
                i += 1;
                if (this.isIntro()) {
                    this.scrollToIntro();
                } else if (i <= this.rows) {
                    this.scrollToView(`entry${i}`);
                } else {
                    this.scrollToView('navArrows', 'end');
                }
                this.arrowsEnabled = true;
            }
        },
        scrollUp() {
            if (this.arrowsEnabled) {
                this.arrowsEnabled = false;
                let i = this.entryInViewport();
                i -= 1;
                if (this.checkIfInViewport(document.getElementsByClassName('nextArrow')[0])) {
                    this.scrollToView(`entry${this.rows}`);
                } else if (i >= 1) {
                    this.scrollToView(`entry${i}`);
                } else if (i === 0) {
                    this.scrollToView('intro');
                }
                this.arrowsEnabled = true;
            }
        },
        entryInViewport() {
            const visibleEls = [];
            let res;
            for (let x = 0; x < this.rows; x++) {
                const el1 = document.getElementsByClassName(`image${x+1}`)[0];
                const el2 = document.getElementsByClassName(`text${x+1}`)[0];
                const el3 = document.getElementsByClassName(`signature${x+1}`)[0];
                let isImageVisible = this.checkIfInViewport(el1);
                let isTextVisible = this.checkIfInViewport(el2);
                let isSignatureVisible = this.checkIfInViewport(el3);
                if (isImageVisible) visibleEls.push(el1)
                if (isTextVisible) visibleEls.push(el2)
                if (isSignatureVisible) visibleEls.push(el3)
                if (isTextVisible || isImageVisible || isSignatureVisible) res = x+1
            }
            return res;
        },
        isIntro() {
            const arr = [];
            let intro;
            arr.push(document.getElementsByClassName(`introText`)[0], document.getElementsByClassName(`introImg`)[0], document.getElementsByClassName(`introSignature`)[0]);
            for (let x = 0; x < arr.length; x++) {
                intro = this.checkIfInViewport(arr[x]);
                intro = this.checkIfPartlyInViewport(arr[x]);
                if (intro) return true;
            }
        },
        scrollToIntro() {
            this.scrollToView('entry1');
        },
        scrollToView(element) {
            const el = document.getElementsByClassName(element)[0];
            this.$scrollTo(el, 500);
        },
        checkIfInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight+1 || document.documentElement.clientHeight+1) &&
                rect.right <= (window.innerWidth+1 || document.documentElement.clientWidth+1)
            )
        },
        checkIfPartlyInViewport(element) {
            const rect = element.getBoundingClientRect();
            return rect.top < document.documentElement.clientHeight && rect.top > 0;
        },
    }
}
</script>
<style lang="scss">
#timespanWrapper {
    height: auto;
    min-height: 100%;
    width: 100vw;
    background-color: var(--timespan-background-color-dark);
    display: flex;

    &.blueBackground {
        background-color: var(--timespan-background-color-light);
    }
}

.arrows {
    position: absolute;
    bottom: 38px;
    left: 64px;

    svg {
        height: 90px;
    }
}

.arrowDown {
    position: relative;
    left: 27px;
    transform: rotate(-90deg);
}

.underheaderText {
    height: 70vh;
}

.leftSide {
    width: 50%;
}
</style>