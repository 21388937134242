import Vue from 'vue'
import App from './App.vue'
import {InlineSvgPlugin} from 'vue-inline-svg';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Homepage from './components/Homepage.vue';
import TimespanPage from './components/TimespanPage.vue';
import IdleVue from 'idle-vue';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false
Vue.use(InlineSvgPlugin);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueScrollTo, {
     container: "#timespanDetailsWrapper",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
});
Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
});

const routes = [
  {path: '', redirect: '/home'},
  {path: '/home', component: Homepage},
  {path: '/timespan/:year', name: 'timespan', component: TimespanPage, props: true},
]

const router = new VueRouter({
  routes,
});

const store = new Vuex.Store({
  state: {
    language: 'no',
    transitionMode: 'slide-up'
  },
  mutations: {
    changeLanguage(state) {
      state.language = state.language === 'no' ? 'uk' : 'no';
    },
    changeTransitionMode(state, mode) {
      state.transitionMode = mode;
    },
  },
});

const eventsHub = new Vue();
setTimeout(() => {
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 90000,
    startAtIdle: false
  });
}, 1000);

Vue.mixin({
  computed: {
    isEnglish() {
      return this.$store.state.language === 'uk';
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    chosenLanguage() {
      return this.$store.state.language;
    }
  },
  methods: {
    redirectHome() {
      this.$router.push({path: '/home'}).catch((err) => {
          if (err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')) {
          console.error(err);
          }        
      });
    },
    redirectSpecific(params) {
      this.$router.push(params).catch((err) => {
        if (err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')) {
        console.error(err);
        }        
      });
    }
  }
});

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
